<template>
    <div>
      <section class="section section-blog section-shaped section-lg my-0">
        <div class="shape shape-style-1 bg-gradient-white">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container pt-lg-md" ref="postContainer">
            <div class="row justify-content-center align-items-center">
              <div class="col-lg-12 text-center">
            <div class="mb-5 mb-lg-0" v-if="post != null">
              <h4 class="display-2 text-uppercase font-weight-300 blog-post-top-heading">
                {{ post.title }}
              </h4>
              <div class="p-5">
                <img
                  v-lazy="
                    $options.filters.postimgsrc(post.featuredImage)
                  "
                  alt="PMS Hormonal Imbalance"
                  class="img-fluid"
                />
              </div>
              <div class="m-5 text-left" v-html="post.content">

              </div>

            </div>
          </div>
            </div>

        </div>
    </section>
    </div>
</template>
<script>

export default {
  name: 'blogpost',
  metaInfo () {
    return {
      title: this.post != null ? this.post.title : 'Blog Post' // ! Once post is loaded, the title will also be changed for SEO purpose
    }
  },
  data () {
    return {
      post: null
    }
  },
  mounted: function () {
    if (this.$route.params.slug !== undefined) {
      /**
       * * Update Form
       * ! It is to open form for Update
       *
       */
      this.getBlogPostDetails(this.$route.params.slug)
    } else {
      /**
       * ? Wrong URL
       * * Send it Back to Blog Page
       */
      this.$router.push('/blog')
    }
  },
  methods: {
    async getBlogPostDetails (slug) {
      try {
        const loader = this.$loading.show({ container: this.$refs.postContainer })
        const response = await window.axios.get('/api/posts/' + slug)
        loader.hide()
        if (response.data) {
          console.log(response.data)
          this.post = response.data
        } else {
          console.log('no data found')
          /**
           * ? Wrong URL
           * * Send it Back to Blog Page
           */
          this.$router.push('/blog')
        }
      } catch (error) {
        if (error) {
          console.log('There was an error', 'Error')
        }
      }
    }
  }
}
</script>
<style scoped>
.blog-post-top-heading{
  letter-spacing: .4em;
    line-height: 2.5em;
}
</style>
