<template>
<section class="section bg-gradient-warning">
      <div class="container">
        <div class="row">
          <div class="col-12 mt-5" ref="testimonialsContainer">
            <h2 class="text-black text-center"> Testimonials </h2>
                        <b-carousel id="carousel1"
                        :interval="6000"
                                    indicators>
                            <!-- Text slides with image -->
                            <b-carousel-slide caption="" v-for="testimonial in testimonials" :key="testimonial.id">
                            <template #img>
                              <img
                                class="d-block img-fluid w-100 slide-image-blank"
                                :src="'img/blank.png' | imgsrc"
                                alt="PMS Hormonal Imbalance"
                              >
                            </template>
                            <h5 class="mt-4 mb-0"><strong class="text-dark text-uppercase">{{testimonial.author}}</strong></h5>
                            <h6 class="text-dark m-0">{{testimonial.about_author}}</h6>
                            <div class="m-0 pt-3 text-dark" v-html="testimonial.content"></div>
                            </b-carousel-slide>
                            <!-- <b-carousel-slide img-src="img/theme/img-2-1200x1000.jpg"></b-carousel-slide> -->
                        </b-carousel>

          </div>

        </div>
        </div>
    </section>
</template>
<script>
import { BCarousel } from 'bootstrap-vue/esm/components/carousel/carousel'
import { BCarouselSlide } from 'bootstrap-vue/esm/components/carousel/carousel-slide'

export default {
  components: {
    BCarousel,
    BCarouselSlide
  },
  data () {
    return {
      testimonials: []
    }
  },
  mounted: function () {
    this.getTestimonials()
  },
  methods: {
    async getTestimonials () {
      try {
        const loader = this.$loading.show({ container: this.$refs.testimonialsContainer })
        const response = await window.axios.get('/api/testimonials/normal')
        loader.hide()
        // console.log(response.data)
        this.testimonials = response.data
      } catch (error) {
        if (error) {
          console.log('There was an error', 'Error')
        }
      }
    }
  }
}
</script>
<style scoped>
.slide-image-blank{
height: 400px;
}
@media(max-width:767px) {
  .slide-image-blank{
height: 450px;
}
}
</style>
