<template>
    <div>
      <section class="section section-contact section-shaped section-lg my-0">
        <div class="shape shape-style-1 bg-gradient-default">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container pt-lg-md">
            <div class="row justify-content-center align-items-center">
              <div class="col-lg-7 text-center">
            <div class="mb-5 mb-lg-0">
              <h4 class="text-white mt-2">
                <a href="https://balihealing.janeapp.com/#/staff_member/3">Book Online here with the Bali Healing Centre online booking system</a>
              </h4>

              <h4 class="text-white mt-2">
                Phone or message for an appointment and request Michelle
              </h4>
              <p class="text-white">
                WA.   +62 81 138 800 40 or WA.    +62 81 388 128 563
</p>

<p class="text-white">
Or message Michelle directly on WhatsApp (messages only)
<br>
WA.   +62 8214 7819 086

              </p>
              <h4 class="text-white mt-2">
                Hours
              </h4>
              <p class="text-white">
                Appointments are from Monday - Friday from 7.30 am to 4pm Bali time.<br>
The clinic is located on the popular Batu Bolong street, Canggu 100m from the beach.
              </p>

            </div>
          </div>
                <div class="col-lg-5">
                    <card gradient="secondary" shadow body-classes="p-lg-5">
                      <form
                ref="contactForm"
                @submit.prevent="saveForm"
              >
                            <h4 class="mb-1">CONTACT US</h4>
                            <base-input
                            v-model="contact.first_name"
                                        placeholder="First name"
                                        addon-left-icon="ni ni-user-run">
                            </base-input>
                            <base-input
                            v-model="contact.last_name"
                                        placeholder="Last name"
                                        addon-left-icon="ni ni-user-run">
                            </base-input>
                            <base-input
                            v-model="contact.email"
                                        placeholder="Email address"
                                        addon-left-icon="ni ni-email-83">
                            </base-input>
                            <base-input
                            v-model="contact.phone"
                                        placeholder="Phone"
                                        addon-left-icon="ni ni-mobile-button">
                            </base-input>
                            <base-input class="mb-4">
                                    <textarea v-model="contact.contents" class="form-control" name="name" rows="4"
                                              cols="80" placeholder="Type a message..."></textarea>
                            </base-input>
                            <base-alert type="success" icon="ni ni-like-2" dismissible v-if="success">
                <span slot="text"><strong>Sent!</strong> We have recieved your email. Please check your email for further details!</span>
            </base-alert>
            <modal :show.sync="success"
                   gradient="primary"
                   modal-classes="modal-danger modal-dialog-centered">
                <h6 slot="header" class="modal-title" id="modal-title-notification">Your attention is required</h6>

                <div class="py-3 text-center">
                    <i class="ni ni-bell-55 ni-3x"></i>
                    <h4 class="heading mt-4">Email Sent Successfully!</h4>
                    <p>We have recieved your email. Please check your email for further details!</p>
                </div>

                <template slot="footer">
                    <base-button type="white" @click="success = false">Ok, Got it</base-button>
                    <base-button type="link"
                                 text-color="white"
                                 class="ml-auto"
                                 @click="success = false">
                        Close
                    </base-button>
                </template>
            </modal>
            <base-alert type="danger" icon="ni ni-support-16" dismissible v-if="error">
                <span slot="text"><strong>Error!</strong> Some error is observed, please try again. Apologies for the inconvenience</span>
            </base-alert>
            <modal :show.sync="error"
                   gradient="danger"
                   modal-classes="modal-danger modal-dialog-centered">
                <h6 slot="header" class="modal-title" id="modal-title-notification">Your attention is required</h6>

                <div class="py-3 text-center">
                    <i class="ni ni-bell-55 ni-3x"></i>
                    <h4 class="heading mt-4">Apologies for the inconvinance!</h4>
                    <p>Some error is observed, please try again. Apologies for the inconvenience</p>
                </div>

                <template slot="footer">
                    <base-button type="white" @click="error = false">Ok, Got it</base-button>
                    <base-button type="link"
                                 text-color="white"
                                 class="ml-auto"
                                 @click="error = false">
                        Close
                    </base-button>
                </template>
            </modal>
                            <base-button type="default" round block size="lg" native-type="submit">
                                Send Message
                            </base-button>
                      </form>
                        </card>
                </div>
            </div>
            <div class="row justify-content-center align-items-center mt-5">
              <div class="col-lg-12 text-center">
                  <h4 class="text-white mt-2">
                CLINIC LOCATION:
              </h4>
              <h5 class="text-white">
                Balihealing Centre
              </h5>
              <p class="text-white">
                Jl. Pantai Batu Bolong No.117x Canggu BALI
              </p>
              <p class="text-white">
                Head to “Old Mans” on the beach, its on the right just after “Raw Beach Garden”, look for the Blue Sign “BaliHealing Centre”  if u reach “The Aston” you have gone too far.
              </p>
              <base-button
                data-aos="zoom-in"
                tag="a"
                                         href="#"
                                         type="black"
                                         icon="ni ni-chat-round"
                                         class="mb-3 mb-sm-0 mt-4 btn-dark">
                                ZOOM CONSULTS
                            </base-button>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>
export default {
  metaInfo: {
    title: 'Contact'
  },
  components: {
    Modal: () => import('@/components/Modal.vue')
  },
  name: 'contact',
  data () {
    return {
      success: false,
      error: false,
      contact: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        contents: null
      }
    }
  },
  methods: {
    async saveForm () {
      const loader = this.$loading.show({ container: this.$refs.contactForm })
      try {
        const response = await window.axios.post('/api/contact', {
          data: this.contact
        })
        loader.hide()
        this.success = true
        console.log(response.data.ResponseHeader.ResponseMessage)
      } catch (error) {
        if (error) {
          this.error = true
          loader.hide()
          console.log('Some Error Happened')
        }
      }
    }
  }
}
</script>
<style scoped>
</style>
