<template>
    <div>
        <section class="section section-blog section-shaped section-lg my-0">
            <div class="shape shape-style-1 bg-gradient-white">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div class="container pt-lg-md" ref="BlogPosts">
              <div class="row row-grid align-items-center"
              v-match-heights="{
    el: ['h6', '.blog-post-top-img','.card','.card-body'],  // Array of selectors to fix
  }">
          <div class="col-md-4 py-5" v-for="post in posts" :key="post.id">
            <router-link :to="'/blog/'+post.slug">
            <card class="border-0" hover shadow body-classes="p-0 text-center">
                <img
                  v-lazy="
                    $options.filters.postimgsrc(post.featuredImage)
                  "
                  alt="PMS Hormonal Imbalance"
                  class="card-img-top blog-post-top-img"
                />

                <h6 class="text-dark font-weight-bold mt-2">{{ post.title }}</h6>
                <!-- <p class="description mt-3">Family Gatherings While TTC</p> -->
            </card>
            </router-link>
          </div>

        </div>
            </div>
            </section>
    </div>
</template>
<script>

export default {
  name: 'blog',
  metaInfo: {
    title: 'Blog'
  },
  data () {
    return {
      posts: []
    }
  },
  mounted: function () {
    this.getBlogPosts()
  },
  methods: {
    async getBlogPosts () {
      try {
        const loader = this.$loading.show({ container: this.$refs.BlogPosts })
        const response = await window.axios.get('/api/posts')
        loader.hide()
        // console.log(response.data)
        this.posts = response.data
      } catch (error) {
        if (error) {
          console.log('There was an error', 'Error')
        }
      }
    }
  }
}
</script>
<style scoped>
/* .blog-post-top-img{
  min-height: 674px;
} */
</style>
